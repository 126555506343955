import React from 'react';
import { useRouter, NextRouter } from 'next/router';
import { Footer } from '@/fe-components/footer';
import { AuthenticationContainer } from '@/fe-containers/authentication';

const AuthenticatePage = () => {
  const router: NextRouter = useRouter();

  return (
    <>
      <AuthenticationContainer
        isOpen={true}
        invitationToken={router?.query?.token as string}
        returnToPath={router?.query?.returnTo as string}
      />
      <Footer />
    </>
  );
};

export default AuthenticatePage;
